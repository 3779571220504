<template>
  <v-container class="pb-2">
    <v-row>
      <v-col cols="1" align="right" offset="11">
        <v-btn icon @click.stop="toggleMenu" class="float-right">
          <v-icon x-large>mdi-menu</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <Menu ref="refMenu"></Menu>
    <h2>Последние заказы</h2>
    <div class="list-orders">
      <v-list two-line>
        <v-list-item-group
          active-class="green--text lighten-1--text"
          multiple
        >
          <template v-for="(item, i) in orders">
            <v-list-item :key="i" @click="open(item)">
              <template>
                <v-list-item-content>
                  <v-list-item-title class="green--text darken-2--text font-weight-bold" v-text="'№ ' + item.token"></v-list-item-title>

                  <v-list-item-subtitle
                    v-text="'Дата: ' + item.date.split('-').reverse().join('.')"
                    class="text--primary"
                  ></v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action>
                  <v-icon color="">
                    mdi-arrow-right
                  </v-icon>
                </v-list-item-action>
              </template>
            </v-list-item>

            <v-divider
              v-if="i < orders.length - 1"
              :key="i + 'div'"
            ></v-divider>
          </template>
        </v-list-item-group>
      </v-list>
    </div>
  </v-container>
</template>
<script>
import Menu from '../components/Menu'

export default {
  components: { Menu },
  props: ['date', 'token'],
  data: () => ({
    orders: []
  }),
  created () {
    this.$ls.get('ordersHistory').reverse().forEach(order => {
      if (order.token && order.date) {
        this.orders.push(order)
      }
    })
  },
  methods: {
    toggleMenu () {
      this.$refs.refMenu.toggle()
    },
    open (item) {
      this.$router.push('/result/' + item.token + '/' + item.date)
    }
  }
}
</script>
<style>
h1 {
  line-height: 1em;
  font-size: 26px;
  margin-bottom: 10px;
  margin-top: 20px;
}

h3 {
  line-height: 1em;
  font-size: 20px;
  margin-bottom: 10px;
  font-weight: normal;
}

.history-info {
  padding-top: 30px;
  padding-bottom: 20px;
}

.list-orders {
  padding-top: 20px;
}

</style>
